/**
 * Helper for array filtering: using this in a `array.map(...).filter(notEmpty)` manner allows the map-resulttype to contain only
 * actually mapped values. Your mapping function can return `null` or `undefined` to omit an element from the resulting array, either
 * based on the fact that an input is also `null`/`undefined` or because of some other arbitrary reason.
 *
 * @param element Element to check
 * @returns a type guard that's true when the element is not undefined and not null, thus it has a value
 */
export function notUndefinedOrNull<T>(
    element: T | undefined | null
): element is T {
    return element !== undefined && element !== null;
}
