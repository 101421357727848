import React from "react";
import { useForm } from "react-hook-form";
import { FormProvider } from "react-hook-form";
import FormLayout from "../organisms/FormLayout/FormLayout";
import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import {
    CreateBusinessAccountDocument,
    CreateBusinessAccountMutation,
} from "src/.graphql/graphql";
import { useNavigate } from "react-router-dom";
import GraphqlErrors from "../atoms/ApolloErrors/ApolloErrors";
import ClientForm from "../organisms/ClientForm/ClientForm";
import { ClientFormProps } from "../organisms/ClientForm/ClientForm.types";
import { useSnackbar } from "src/hooks/useSnackbar/useSnackbar";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
    .object()
    .shape({
        name: yup.string().required("form.validation.required"),
        botsquadBearerToken: yup.string().required("form.validation.required"),
        botsquadBotId: yup.string().required("form.validation.required"),
    })
    .required();

export const ClientCreate = () => {
    const navigate = useNavigate();
    const { createSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const methods = useForm<ClientFormProps>({
        defaultValues: {},
        resolver: yupResolver(schema),
    });

    const [mutateFunction, { error, loading }] =
        useMutation<CreateBusinessAccountMutation>(
            CreateBusinessAccountDocument
        );

    const onSubmit = async (data: ClientFormProps) => {
        try {
            const response = await mutateFunction({
                variables: {
                    input: data,
                },
            });

            createSnackbar({
                content: t("clients.created"),
            });

            navigate(`/clients/${response.data?.createBusinessAccount.id}`);
        } catch {
            createSnackbar({
                type: "error",
                content: t("exeptions.unknown"),
            });
        }
    };

    return (
        <Container>
            <Grid>
                <Paper>
                    <Box
                        sx={{
                            p: 2,
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography variant="h6">
                            {t("clients.create.title")}
                        </Typography>
                    </Box>

                    <FormProvider {...methods}>
                        <FormLayout>
                            <GraphqlErrors error={error} />

                            <ClientForm
                                loading={loading}
                                onSubmit={methods.handleSubmit(onSubmit)}
                            />
                        </FormLayout>
                    </FormProvider>
                </Paper>
            </Grid>
        </Container>
    );
};

export default ClientCreate;
