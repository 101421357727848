import {
    Alert,
    Box,
    CircularProgress,
    Container,
    Grid,
    Paper,
} from "@mui/material";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import BusinessAccountTable from "src/components/organisms/BusinessAccountTable/BusinessAccountTable";
import useClient from "src/hooks/useClient/useClient";
import { useKeycloak } from "src/providers/KeycloakProvider";
import { UserRole } from "src/types/userRoles";

const BusinessAccountValidator = ({ children }: { children: ReactNode }) => {
    const { authenticatedUser, loading } = useClient();
    const { keycloak } = useKeycloak();
    const { t } = useTranslation();

    const shouldChooseAccount = keycloak.hasRealmRole(UserRole.SuperAdmin);

    if (!shouldChooseAccount) return <>{children}</>;

    if (loading) {
        return (
            <Box sx={{ display: "flex" }}>
                <CircularProgress />
            </Box>
        );
    }

    return authenticatedUser ? (
        <>{children}</>
    ) : (
        <Container>
            <Grid maxWidth={800} sx={{ m: "auto" }}>
                <Paper>
                    <Box
                        bgcolor={"lightBackground.main"}
                        sx={{ p: 2 }}
                        component="form"
                        autoComplete="off"
                    >
                        <Alert>{t("clients.choose")}</Alert>
                    </Box>
                    <BusinessAccountTable />
                </Paper>
            </Grid>
        </Container>
    );
};

export default BusinessAccountValidator;
