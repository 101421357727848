import React from "react";
import "./styles/general.css";
import CssBaseline from "@mui/material/CssBaseline";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import { ApolloProvider } from "@apollo/client";
import { apollo } from "src/lib/apollo/client";
import { useKeycloak } from "./providers/KeycloakProvider";
import fetchWithCredentials from "./lib/apollo/fetchWithCredentials";
import createUploadLink from "apollo-upload-client/public/createUploadLink.js";
import { SnackbarProvider } from "./contexts/Snackbar/Snackbar";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./i18n.config";
import { ClientProvider } from "./contexts/Client/Client.provider";

function App() {
    const { keycloak } = useKeycloak();

    const uploadLink = createUploadLink({
        uri: process.env.REACT_APP_API,
        fetch: (uri, options) =>
            fetchWithCredentials({ keycloak, uri, options }),
    });

    const client = apollo.getClient({
        apolloOptions: {
            link: uploadLink,
        },
    });

    return (
        <ApolloProvider client={client}>
            <ClientProvider>
                <SnackbarProvider>
                    <CssBaseline />
                    <RouterProvider router={router} />
                </SnackbarProvider>
            </ClientProvider>
        </ApolloProvider>
    );
}

export default App;
