import React, { useState } from "react";
import AppBar from "../AppBar/AppBar";
import Drawer from "../Drawer/Drawer";
import Box from "@mui/material/Box";
import { DrawerHeader } from "../Drawer/Drawer.styles";
import { LayoutProps } from "./Layout.types";

export const Layout = ({ children }: LayoutProps) => {
    const [open, setOpen] = useState(true);

    const handleClick = () => {
        setOpen((isOpen) => !isOpen);
    };

    return (
        <Box
            sx={{
                display: "flex",
                backgroundColor: "greyBackground.main",
                minHeight: "100vh",
            }}
            data-testid="dashboard"
        >
            <AppBar open={open} onClick={handleClick} />
            <Drawer open={open} onClose={handleClick} />
            <Box
                component={"main"}
                sx={{
                    flexGrow: 1,
                    p: 3,
                }}
            >
                <DrawerHeader />
                {children}
            </Box>
        </Box>
    );
};

export default Layout;
