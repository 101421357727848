import React, { useContext } from "react";
import { createContext, ReactNode } from "react";
import useKeycloakAsContext from "./KeycloadHook";

export const keycloakContext = createContext(
    {} as ReturnType<typeof useKeycloakAsContext>
);

/**
 * Hook to obtain the Keycloak context within components
 * @returns Context-value for Keycloak
 */
export const useKeycloak = () => useContext(keycloakContext);

/**
 * Provider for Keycloak
 *
 * @param children Children to be rendered within this provider
 * @returns Provider linked to a populated context instance
 */
export const KeycloakProvider = ({ children }: { children: ReactNode }) => {
    const keycloak = useKeycloakAsContext();
    const Provider = keycloakContext.Provider;

    return (
        <Provider value={keycloak}>
            {keycloak.loading || !keycloak.initialized ? null : children}
        </Provider>
    );
};

export default KeycloakProvider;
