import { useContext } from "react";
import { SnackbarContextType } from "src/contexts/Snackbar/Snackbar.types";
import { SnackbarContext } from "../../contexts/Snackbar/Snackbar";

export const useSnackbar = (): SnackbarContextType => {
    const context = useContext(SnackbarContext);

    if (context === undefined) {
        throw new Error("useSnackBar must be used within a SnackBarProvider");
    }

    return context;
};

export default useSnackbar;
