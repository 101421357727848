import React from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { ClientFormProps } from "./ClientForm.types";
import ErrorMessages from "src/components/atoms/ErrorMessages/ErrorMessages";

export const ClientForm = ({
    onSubmit,
    loading,
}: {
    onSubmit: (...args: any) => void;
    loading: boolean;
}) => {
    const {
        register,
        formState: { errors },
    } = useFormContext<ClientFormProps>();
    const { t } = useTranslation();

    return (
        <div>
            <Stack spacing={2}>
                <FormControl fullWidth>
                    <TextField
                        {...register("name")}
                        label={t("clients.form.name")}
                        helperText={
                            <ErrorMessages errors={errors} name="name" />
                        }
                        error={errors.name ? true : false}
                        data-test-id="client-name"
                    />
                </FormControl>

                <FormControl fullWidth>
                    <TextField
                        {...register("botsquadBearerToken")}
                        label={t("clients.form.botsquadBearerToken")}
                        helperText={
                            <ErrorMessages
                                errors={errors}
                                name="botsquadBearerToken"
                            />
                        }
                        error={errors.botsquadBearerToken ? true : false}
                        data-test-id="client-botsquadBearerToken"
                    />
                </FormControl>

                <FormControl fullWidth>
                    <TextField
                        {...register("botsquadBotId")}
                        label={t("clients.form.botsquadBotId")}
                        helperText={
                            <ErrorMessages
                                errors={errors}
                                name="botsquadBotId"
                            />
                        }
                        error={errors.botsquadBotId ? true : false}
                        data-test-id="client-botsquadBotId"
                    />
                </FormControl>

                <div>
                    <LoadingButton
                        data-test-id="template-submit"
                        variant="contained"
                        onClick={onSubmit}
                        loading={loading}
                        color="secondary"
                    >
                        {t("clients.create.submit")}
                    </LoadingButton>
                </div>
            </Stack>
        </div>
    );
};

export default ClientForm;
