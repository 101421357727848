import React, { useState } from "react";
import { Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { DocumentNode } from "graphql";
import { useMutation } from "@apollo/client";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const DeleteItem = ({
    onCompleted,
    mutation,
    variables,
}: {
    onCompleted?: () => void;
    mutation: DocumentNode;
    variables: any;
}) => {
    const { t } = useTranslation();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [mutateFunction, { loading }] = useMutation(mutation);

    const executeMutation = async () => {
        try {
            await mutateFunction({
                variables,
            });

            if (onCompleted) {
                setDialogOpen(false);
                onCompleted();
            }
        } catch (e) {
            alert(t("remove-dialog.error"));
        }
    };

    return (
        <>
            <Dialog
                data-test-id="delete-item-dialog"
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
            >
                <DialogTitle>{t("remove-dialog.title")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t("remove-dialog.text")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)}>
                        {t("remove-dialog.buttons.cancel")}
                    </Button>
                    <Button
                        onClick={executeMutation}
                        autoFocus
                        data-test-id="delete-item-button"
                    >
                        {t("remove-dialog.buttons.remove")}
                    </Button>
                </DialogActions>
            </Dialog>
            <LoadingButton
                data-test-id="delete-item"
                loading={loading}
                onClick={() => setDialogOpen(true)}
            >
                <Delete />
            </LoadingButton>
        </>
    );
};

export default DeleteItem;
