import React from "react";
import ReactDOM from "react-dom/client";
import "./i18n.config.ts";
import App from "./App";
import { KeycloakProvider } from "./providers/KeycloakProvider";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./styles/theme";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <KeycloakProvider>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </KeycloakProvider>
    </React.StrictMode>
);

reportWebVitals();
