import { styled } from "@mui/material/styles";

export const UploadInput = styled("label")(({ theme }) => ({
    input: {
        position: "absolute",
        left: "-99999rem",
    },
    ".button": {
        position: "absolute",
        right: "1rem",
        top: "50%",
        transform: "translateY(-50%)",
        pointerEvents: "none",
    },
    ".label": {
        color: theme.palette.inputColor.main,
        "&.active": {
            color: theme.palette.defaultColor.main,
        },
    },
    display: "flex",
    alignItems: "center",
    position: "relative",
    border: `1px solid ${theme.palette.defaultBorder.main}`,
    height: "60px",
    width: "100%",
    paddingLeft: "1rem",
    paddingRight: "8rem",
    cursor: "pointer",
    borderRadius: "0.3rem",
    "&:hover": {
        border: `1px solid ${theme.palette.defaultColor.main}`,
    },
}));
