import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { FormProvider } from "react-hook-form";
import { TemplateFormProps } from "src/components/organisms/TemplateForm/TemplateForm.types";
import TemplateForm from "src/components/organisms/TemplateForm/TemplateForm";
import FormLayout from "../organisms/FormLayout/FormLayout";
import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { CreateTemplateDocument } from "src/.graphql/graphql";
import { useNavigate } from "react-router-dom";
import GraphqlErrors from "../atoms/ApolloErrors/ApolloErrors";
import { TemplateButtonType } from "src/types/template/TemplateButton";
import templateButtonsMapper from "src/mappers/templateButtonsMapper";
import WhatsappMessage from "../atoms/WhatsappMessage/WhatsappMessage";
import useSnackbar from "src/hooks/useSnackbar/useSnackbar";

export const TemplateCreate = () => {
    const navigate = useNavigate();
    const [message, setMessage] = useState<any>();
    const { t } = useTranslation();
    const { createSnackbar } = useSnackbar();
    const methods = useForm<TemplateFormProps>({
        defaultValues: {
            buttonTypes: "NO_BUTTONS",
            buttons: [
                {
                    type: TemplateButtonType.URL,
                },
                {
                    type: TemplateButtonType.PHONE_NUMBER,
                },
            ],
        },
    });

    const [mutateFunction, { error, loading }] = useMutation(
        CreateTemplateDocument
    );

    const onSubmit = async (data: TemplateFormProps) => {
        try {
            await mutateFunction({
                variables: {
                    input: {
                        uniqueName: data.name,
                        components: {
                            body: {
                                text: data.message,
                            },
                            buttons: templateButtonsMapper(data),
                        },
                        language: "NL",
                        category: "MARKETING",
                    },
                },
            });

            navigate("/templates");
        } catch {
            createSnackbar({
                type: "error",
                content: t("exeptions.unknown"),
            });
        }
    };

    methods.watch((_data) => {
        const data = _data as TemplateFormProps;

        setMessage({
            message: data.message,
            buttons: templateButtonsMapper(data),
        });
    });

    return (
        <Container>
            <Grid>
                <Paper>
                    <Box
                        sx={{
                            p: 2,
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography variant="h6">
                            {t("templates.create.title")}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            p: 2,
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} lg={8}>
                                <FormProvider {...methods}>
                                    <FormLayout>
                                        <GraphqlErrors error={error} />

                                        <TemplateForm
                                            loading={loading}
                                            onSubmit={methods.handleSubmit(
                                                onSubmit
                                            )}
                                        />
                                    </FormLayout>
                                </FormProvider>
                            </Grid>

                            <Grid item xs={12} md={6} lg={4}>
                                <Typography variant="h6" sx={{ mb: 2 }}>
                                    {t("preview")}
                                </Typography>
                                <WhatsappMessage {...message} />
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Grid>
        </Container>
    );
};

export default TemplateCreate;
