import React, { useEffect, useState } from "react";
import { UploadInput } from "./UploadField.styles";
import FormControl from "@mui/material/FormControl";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormHelperText from "@mui/material/FormHelperText";
import classNames from "classnames";
import { Button, Typography } from "@mui/material";
import { UploadFieldProps } from "./UploadField.types";

export const UploadField = ({
    error,
    name,
    label,
    buttonLabel,
    required,
}: UploadFieldProps) => {
    const { register, getValues, watch } = useFormContext();
    const { t } = useTranslation();
    const [fileName, setFilename] = useState(
        label || t("campaigns.create.upload-file")
    );

    useEffect(() => {
        if (getValues("file") && getValues("file").length) {
            setFilename(getValues("file")[0].name);
        }
    }, [watch(name)]);

    return (
        <FormControl fullWidth error={error && required}>
            <UploadInput>
                <input
                    {...register(name, { required: true })}
                    type="file"
                    required
                    accept=".csv"
                    data-test-id="campaign-file"
                />
                <Typography
                    className={classNames("label", {
                        ["active"]:
                            fileName !== t("campaigns.create.upload-file") &&
                            fileName !== label,
                    })}
                >
                    {fileName}
                </Typography>
                <Button
                    variant="contained"
                    className="button"
                    color="secondary"
                >
                    {buttonLabel || t("campaigns.create.upload")}
                </Button>
            </UploadInput>
            {error && required ? (
                <FormHelperText>{t("form.field-is-required")}</FormHelperText>
            ) : null}
        </FormControl>
    );
};

export default UploadField;
