import React from "react";
import { Box, IconButton, Toolbar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBarProps } from "./AppBar.types";
import { AppBar as StyledAppbar, Logo } from "./AppBar.styles";
import AccountMenu from "../AccountMenu/AccountMenu";
import ClientSelect from "../BusinessAccountSelect/BusinessAccountSelect";
import { useKeycloak } from "src/providers/KeycloakProvider";
import { UserRole } from "src/types/userRoles";

export const AppBar = ({ onClick, open }: AppBarProps) => {
    const { keycloak } = useKeycloak();

    return (
        <StyledAppbar position="fixed" open={open}>
            <Toolbar>
                <IconButton onClick={onClick} edge="start" color="inherit">
                    <MenuIcon />
                </IconButton>
                <Logo>
                    <img src={"/assets/convergage.png"} />
                </Logo>

                {keycloak.hasRealmRole(UserRole.SuperAdmin) && <ClientSelect />}

                <Box sx={{ flexGrow: 1 }} />

                <AccountMenu />
            </Toolbar>
        </StyledAppbar>
    );
};

export default AppBar;
