import * as React from "react";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import SnackbarComponent from "@mui/material/Snackbar";

import { useSnackbar } from "../../../hooks/useSnackbar/useSnackbar";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
) {
    return <MuiAlert ref={ref} elevation={6} variant={"filled"} {...props} />;
});

export const Snackbar = () => {
    const { snackbar, isOpen, closeSnackbar } = useSnackbar();
    const { type = "success" } = snackbar || {};

    return (
        <SnackbarComponent
            autoHideDuration={6000}
            onClose={closeSnackbar}
            open={isOpen}
            data-test-id="snackbar"
        >
            <Alert
                onClose={closeSnackbar}
                severity={type}
                sx={{ width: "100%" }}
            >
                {snackbar?.content}
            </Alert>
        </SnackbarComponent>
    );
};

export default Snackbar;
