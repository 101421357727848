import { useApolloClient } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import {
    CreateActOnBehalfOfTokenDocument,
    CreateActOnBehalfOfTokenMutation,
} from "src/.graphql/graphql";
import jwt_decode from "jwt-decode";

export const useClientHook = () => {
    const [initialized, setInitialized] = useState(false);
    const [accountId, _setAccountId] = useState<string>();
    const [authenticatedUser, setAuthenticatedUser] = useState<{
        token: string;
        businessAccount: {
            id: string;
            name: string;
        };
    }>();
    const [loading, setLoading] = useState(false);
    const apollo = useApolloClient();

    useEffect(() => {
        if (initialized) return;

        const account = window.localStorage.getItem("businessAccountId");

        setAccountId(account || undefined);
        setInitialized(true);
    }, []);

    const getToken = async (_accountId: string | undefined) => {
        if (!_accountId) return;

        setLoading(true);

        const response = await apollo
            .mutate<CreateActOnBehalfOfTokenMutation>({
                mutation: CreateActOnBehalfOfTokenDocument,
                variables: {
                    businessAccountId: _accountId,
                },
            })
            .catch(() => {
                setAuthenticatedUser(undefined);
            });

        if (response?.data?.createActOnBehalfOfToken) {
            setAuthenticatedUser(response.data.createActOnBehalfOfToken);
        }

        setLoading(false);
    };

    const setAccountId = async (accountId: string | undefined) => {
        // update localstorage
        if (accountId) {
            window.localStorage.setItem("businessAccountId", accountId);
        } else {
            window.localStorage.removeItem("businessAccountId");
        }

        _setAccountId(accountId);

        if (accountId) {
            getToken(accountId);
        } else {
            setAuthenticatedUser(undefined);
        }
    };

    const refreshToken = useCallback(async () => {
        if (!authenticatedUser || !authenticatedUser.token) return;

        const decodedToken = jwt_decode<{ exp: number }>(
            authenticatedUser.token
        );

        if (decodedToken.exp <= Math.floor(Date.now() / 1000)) {
            await getToken(accountId);
        }
    }, [authenticatedUser, getToken, accountId]);

    return {
        accountId,
        authenticatedUser,
        setAccountId,
        loading,
        initialized,
        refreshToken,
    };
};
