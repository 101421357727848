
      export type PossibleTypesResultData = {
  "possibleTypes": {
    "PaginationOutput": [
      "PaginatedBusinessAccountsDto",
      "PaginatedCampaignsDto",
      "PaginatedTemplatesDto"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "PaginationOutput": [
      "PaginatedBusinessAccountsDto",
      "PaginatedCampaignsDto",
      "PaginatedTemplatesDto"
    ]
  }
};
      export default result;
    