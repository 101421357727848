import React from "react";
import {
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemButton,
} from "@mui/material";
import { MenuItemProps } from "./MenuItem.types";
import { NavLink } from "react-router-dom";

const getProps = (href: string, hideText: boolean, external?: boolean) => {
    if (external)
        return {
            component: "a",
            href: href,
            target: "_blank",
        };
    return {
        component: NavLink,
        to: href || "",
        sx: {
            minHeight: 48,
            justifyContent: hideText ? "initial" : "center",
            px: 2.5,
        },
    };
};

export const MenuItem = ({
    icon,
    text,
    hideText,
    href,
    external,
}: MenuItemProps) => {
    return (
        <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
                {...getProps(href || "", hideText || false, external)}
            >
                <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: hideText ? 3 : "auto",
                        justifyContent: "center",
                    }}
                >
                    {icon}
                </ListItemIcon>
                <ListItemText
                    primary={text}
                    sx={{ opacity: hideText ? 1 : 0 }}
                />
            </ListItemButton>
        </ListItem>
    );
};

export default MenuItem;
