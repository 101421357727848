import React from "react";
import { Add } from "@mui/icons-material";
import {
    Box,
    Button,
    CircularProgress,
    Container,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useGetBusinessAccountByIdQuery } from "src/.graphql/graphql";

export const ClientDetail = () => {
    const { t } = useTranslation();
    const { id: businessAccountId } = useParams();

    const { data, loading } = useGetBusinessAccountByIdQuery({
        fetchPolicy: "cache-and-network",
        variables: {
            id: businessAccountId,
        },
    });

    return (
        <Container>
            {loading || !data?.getBusinessAccountById ? (
                <Box sx={{ display: "flex" }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Paper>
                            <Box
                                sx={{
                                    p: 2,
                                }}
                            >
                                <Typography variant="h6">Klant</Typography>
                                {data.getBusinessAccountById.name}
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Paper>
                            <Box
                                sx={{
                                    p: 2,
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography variant="h6">
                                    {t("users.title")}
                                </Typography>
                                <Button
                                    data-test-id="createTemplate"
                                    component={Link}
                                    to={`/clients/${businessAccountId}/users/create`}
                                    startIcon={<Add />}
                                    variant="contained"
                                    color="secondary"
                                >
                                    {t("users.create.title")}
                                </Button>
                            </Box>

                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                Voornaam
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                Voornaam
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                E-mailadres
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(
                                            data?.getBusinessAccountById
                                                ?.users || []
                                        ).map((user) => (
                                            <TableRow hover key={user.id}>
                                                <TableCell>
                                                    {user.firstName}
                                                </TableCell>
                                                <TableCell>
                                                    {user.lastName}
                                                </TableCell>
                                                <TableCell>
                                                    {user.email}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>
                </Grid>
            )}
        </Container>
    );
};

export default ClientDetail;
