import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
    DeleteTemplateDocument,
    GetTemplatesQuery,
    useGetTemplatesQuery,
} from "src/.graphql/graphql";
import {
    DataGrid as MuiDataGrid,
    GridColumns,
    GridValueGetterParams,
} from "@mui/x-data-grid";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { Add } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import DeleteItem from "../molucules/DeleteItem/DeleteItem";

export const TemplateRoot = () => {
    const { t } = useTranslation();
    const pageSize = 10;
    const [page, setPage] = useState(0);
    const [pageData, setPageData] = React.useState<GetTemplatesQuery>();

    const columns: GridColumns = [
        {
            field: "name",
            headerName: "Naam",
            width: 300,
            sortable: false,
            filterable: false,
        },
        {
            field: "status",
            headerName: "Status",
            flex: 1,
            minWidth: 150,
            sortable: false,
            filterable: false,
            valueGetter: (
                params: GridValueGetterParams<
                    any,
                    GetTemplatesQuery["getTemplates"]["rows"][number]
                >
            ) => t(`templates.status.${params.row.status}`),
        },
        {
            field: "actions",
            type: "actions",
            flex: 0,
            getActions: (params) => [
                <DeleteItem
                    key="delete"
                    onCompleted={refetch}
                    mutation={DeleteTemplateDocument}
                    variables={{
                        input: {
                            id: params.id,
                        },
                    }}
                />,
            ],
        },
    ];

    const { data, loading, refetch } = useGetTemplatesQuery({
        fetchPolicy: "cache-and-network",
        variables: {
            pagination: {
                pageSize,
                page,
            },
        },
    });

    // prevent empty table because data is undefined if table is loading
    useEffect(() => {
        if (data) {
            setPageData(data);
        }
    }, [data]);

    const handlePageChange = (page: number) => {
        setPage(page);
    };

    return (
        <Container>
            <Grid>
                <Paper>
                    <Box
                        sx={{
                            p: 2,
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography variant="h6">
                            {t("templates.title")}
                        </Typography>
                        <Button
                            data-test-id="createTemplate"
                            component={Link}
                            to="/templates/create"
                            startIcon={<Add />}
                            variant="contained"
                            color="secondary"
                        >
                            {t("templates.add")}
                        </Button>
                    </Box>

                    <MuiDataGrid
                        sx={{ boxShadow: 0, border: 0 }}
                        paginationMode="server"
                        rowsPerPageOptions={[pageSize]}
                        loading={loading}
                        rowCount={pageData?.getTemplates.rowCount || 0}
                        page={page}
                        rows={pageData?.getTemplates.rows || []}
                        columns={columns}
                        pageSize={pageSize}
                        onPageChange={(newPage) => handlePageChange(newPage)}
                        disableSelectionOnClick={true}
                        autoHeight
                    />
                </Paper>
            </Grid>
        </Container>
    );
};

export default TemplateRoot;
