export function whatsToHtml(msg: string) {
    if (!msg) return "";

    return msg
        .replace(/~(~*[^~\n]+~*)~/g, "<del>$1</del>")
        .replace(/_(_*[^_\n]+_*)_/g, "<em>$1</em>")
        .replace(/\*(\**[^*\n]+\**)\*/g, "<strong>$1</strong>")
        .replace(/`{3}(`*[^`\n]+`*)`{3}/g, "<pre>$1</pre>")
        .replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, "$1<br />$2");
}
