import styled from "@emotion/styled";

export const WhatsappMessageStyled = styled.div`
    --border-color: rgb(228 228 228 / 87%);

    border: 1px solid var(--border-color);
    border-radius: 0.25rem;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
`;

export const MessageStyled = styled.div`
    padding: 1rem;

    pre {
        display: inline;
        margin: 0;
    }
`;

export const ButtonsStyled = styled.div`
    border-top: 1p solid var(--border-color);
`;

export const ButtonStyled = styled.div`
    padding: 0.75rem 1rem;
    border-top: 1px solid var(--border-color);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 200ms background-color;

    svg {
        margin-right: 0.5rem;
    }

    &:hover {
        background-color: var(--border-color);
    }
`;

export const MessageEmptyStyled = styled.div`
    font-style: italic;
    text-align: center;
    padding: 1rem;
`;
