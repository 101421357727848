import React from "react";
import { useForm } from "react-hook-form";
import { FormProvider } from "react-hook-form";
import FormLayout from "../organisms/FormLayout/FormLayout";
import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import {
    CreateUserForBusinessAccountDocument,
    CreateUserForBusinessAccountMutation,
} from "src/.graphql/graphql";
import { useNavigate, useParams } from "react-router-dom";
import GraphqlErrors from "../atoms/ApolloErrors/ApolloErrors";
import { useSnackbar } from "src/hooks/useSnackbar/useSnackbar";
import BusinessAccountUserForm from "../organisms/BusinessAccountUserForm/BusinessAccountUserForm";
import { BusinessAccountUserFormProps } from "../organisms/BusinessAccountUserForm/BusinessAccountUserForm.types";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
    .object()
    .shape({
        firstName: yup.string().required("form.validation.required"),
        lastName: yup.string().required("form.validation.required"),
        email: yup
            .string()
            .email("form.validation.email")
            .required("form.validation.required"),
    })
    .required();

export const UserCreate = () => {
    const navigate = useNavigate();
    const { id: businessAccountId } = useParams();
    const { createSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const methods = useForm<BusinessAccountUserFormProps>({
        defaultValues: {},
        resolver: yupResolver(schema),
    });

    const [mutateFunction, { error, loading }] =
        useMutation<CreateUserForBusinessAccountMutation>(
            CreateUserForBusinessAccountDocument
        );

    const onSubmit = async (data: BusinessAccountUserFormProps) => {
        try {
            await mutateFunction({
                variables: {
                    input: {
                        businessAccountId,
                        ...data,
                    },
                },
            });

            createSnackbar({
                content: t("users.created"),
            });

            navigate(`/clients/${businessAccountId}`);
        } catch {
            createSnackbar({
                type: "error",
                content: t("exeptions.unknown"),
            });
        }
    };

    return (
        <Container>
            <Grid>
                <Paper>
                    <Box
                        sx={{
                            p: 2,
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography variant="h6">
                            {t("users.create.title")}
                        </Typography>
                    </Box>

                    <FormProvider {...methods}>
                        <FormLayout>
                            <GraphqlErrors error={error} />

                            <BusinessAccountUserForm
                                loading={loading}
                                onSubmit={methods.handleSubmit(onSubmit)}
                            />
                        </FormLayout>
                    </FormProvider>
                </Paper>
            </Grid>
        </Container>
    );
};

export default UserCreate;
