import {
    Box,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Tooltip,
} from "@mui/material";
import React from "react";
import { useKeycloak } from "src/providers/KeycloakProvider";
import AccountCircle from "@mui/icons-material/AccountCircle";
import SignoutIcon from "@mui/icons-material/Logout";
import { useTranslation } from "react-i18next";

export const AccountMenu = () => {
    const { t } = useTranslation();
    const { keycloak } = useKeycloak();

    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
        null
    );

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogout = () => {
        keycloak.logout();
    };

    return (
        <Box sx={{ flexGrow: 0 }} data-testid="account-menu">
            <Tooltip title={t("account-opties")}>
                <IconButton
                    onClick={handleOpenUserMenu}
                    sx={{ p: 0 }}
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
            </Tooltip>
            <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <SignoutIcon></SignoutIcon>
                    </ListItemIcon>
                    <ListItemText>{t("signout")}</ListItemText>
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default AccountMenu;
