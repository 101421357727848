export enum TemplateButtonType {
    QUICK_REPLY = "QUICK_REPLY",
    PHONE_NUMBER = "PHONE_NUMBER",
    URL = "URL",
}

export type TemplateButton = {
    type: TemplateButtonType;
    text: string;
    value?: string;
};
