import { useApolloClient } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import React, { useEffect, useState } from "react";
import { createContext, ReactNode } from "react";
import fetchWithCredentials from "src/lib/apollo/fetchWithCredentials";
import { useKeycloak } from "src/providers/KeycloakProvider";
import { useClientHook } from "./Client.hook";
import { ClientContextType } from "./Client.types";

export const ClientContext = createContext<ClientContextType>({} as any);

export const ClientProvider = ({ children }: { children: ReactNode }) => {
    const clientHook = useClientHook();
    const { keycloak } = useKeycloak();
    const apollo = useApolloClient();
    const [initialized, setInitialized] = useState(false);
    const [activeUser, setActiveUser] = useState<string>();

    // update Apollo client with correct url
    useEffect(() => {
        // dont update apollo link if link is already loaded
        if (activeUser === clientHook.authenticatedUser?.token && initialized) {
            setActiveUser(clientHook.authenticatedUser?.token);
            return;
        }

        setInitialized(false);

        const uploadLink = createUploadLink({
            uri: process.env.REACT_APP_API,
            fetch: (uri, options) =>
                fetchWithCredentials({
                    keycloak,
                    client: clientHook,
                    uri,
                    options,
                }),
        });

        apollo.setLink(uploadLink);

        setActiveUser(clientHook.authenticatedUser?.token);

        if (clientHook.loading === false && clientHook.initialized === true) {
            setInitialized(true);
        }
    }, [
        clientHook.loading,
        clientHook.initialized,
        clientHook.authenticatedUser,
        keycloak,
    ]);

    return (
        <ClientContext.Provider value={clientHook}>
            {initialized && activeUser === clientHook.authenticatedUser?.token
                ? children
                : null}
        </ClientContext.Provider>
    );
};
