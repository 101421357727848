import React from "react";
import { Outlet } from "react-router-dom";
import Layout from "src/components/organisms/Layout/Layout";

export const Dashboard = () => {
    return (
        <Layout>
            <Outlet />
        </Layout>
    );
};

export default Dashboard;
