import React, { useState } from "react";
import { useGetCampaignsQuery } from "src/.graphql/graphql";
import { DataGrid as MuiDataGrid, GridColumns } from "@mui/x-data-grid";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Add } from "@mui/icons-material";

export const CampaignRoot = () => {
    const { t } = useTranslation();
    const pageSize = 30;
    const [page, setPage] = useState(0);

    const columns: GridColumns = [
        {
            field: "id",
            headerName: "Campagne id",
            width: 300,
        },
        {
            field: "name",
            headerName: "Naam",
            width: 300,
        },
    ];

    const { data, loading, error } = useGetCampaignsQuery({
        fetchPolicy: "cache-and-network",
        variables: {
            pagination: {
                pageSize,
                page,
            },
        },
    });

    const handlePageChange = (page: number) => {
        setPage(page);
    };

    if (error) return <div>{t("campaigns.root.error")}</div>;

    return (
        <Container>
            <Grid>
                <Paper>
                    <Box
                        sx={{
                            p: 2,
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography variant="h6">
                            {t("campaigns.title")}
                        </Typography>
                        <Button
                            component={Link}
                            to="/campaigns/create"
                            startIcon={<Add />}
                            variant="contained"
                            data-test-id="createCampaign"
                            color="secondary"
                        >
                            {t("campaigns.add")}
                        </Button>
                    </Box>
                    <MuiDataGrid
                        sx={{ boxShadow: 0, border: 0 }}
                        paginationMode="server"
                        rowsPerPageOptions={[30]}
                        loading={loading}
                        rowCount={data?.getCampaigns.rowCount || 0}
                        page={page}
                        rows={data?.getCampaigns.rows || []}
                        columns={columns}
                        pageSize={pageSize}
                        onPageChange={(newPage) => handlePageChange(newPage)}
                        disableSelectionOnClick={true}
                        autoHeight
                    />
                </Paper>
            </Grid>
        </Container>
    );
};

export default CampaignRoot;
