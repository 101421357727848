import { TemplateFormProps } from "src/components/organisms/TemplateForm/TemplateForm.types";
import { notUndefinedOrNull } from "src/lib/notNullOrUndefined/notNullOrUndefined";
import {
    TemplateButton,
    TemplateButtonType,
} from "src/types/template/TemplateButton";

const getButtons = (data: TemplateFormProps): TemplateButton[] | undefined => {
    let buttons = [];

    console.log(data);

    switch (data.buttonTypes) {
        case "CALL_TO_ACTION":
            buttons = data.buttons
                ?.map((button) =>
                    button.disabled === false
                        ? {
                              type: button.type,
                              text: button.text,
                              value: button.value,
                          }
                        : undefined
                )
                .filter(notUndefinedOrNull);

            break;
        case "QUICK_REPLY":
            buttons = (data.replies || [])
                .map((reply) =>
                    reply.text
                        ? {
                              type: TemplateButtonType["QUICK_REPLY"],
                              text: reply.text,
                          }
                        : undefined
                )
                .filter(notUndefinedOrNull);

            break;
        default:
            return undefined;
    }

    return buttons.length > 0 ? buttons : undefined;
};

export const templateButtonsMapper = (data: TemplateFormProps) => {
    return getButtons(data);
};

export default templateButtonsMapper;
