import { FormControl, Paper, Stack, TextField } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TemplateFormProps } from "../TemplateForm.types";

export const QuickReplyButtons = () => {
    const { register } = useFormContext<TemplateFormProps>();
    const { t } = useTranslation();

    return (
        <Stack spacing={2}>
            {Array(3)
                .fill(null)
                .map((key, index) => (
                    <Paper sx={{ p: 2 }} key={index}>
                        <Stack spacing={1}>
                            <FormControl fullWidth>
                                <TextField
                                    data-test-id="reply-input"
                                    {...register(`replies.${index}.text`)}
                                    size="small"
                                    id="name"
                                    label={t("templates.form.buttons.reply")}
                                />
                            </FormControl>
                        </Stack>
                    </Paper>
                ))}
        </Stack>
    );
};

export default QuickReplyButtons;
