import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { FormLayoutProps } from "./FormLayout.types";

export const FormLayout = ({ children }: FormLayoutProps) => {
    return (
        <Box
            bgcolor={"lightBackground.main"}
            sx={{ p: 2 }}
            component="form"
            autoComplete="off"
            maxWidth={800}
        >
            <Stack spacing={2}>{children}</Stack>
        </Box>
    );
};

export default FormLayout;
