import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import useClient from "src/hooks/useClient/useClient";
import BusinessAccountTable from "../BusinessAccountTable/BusinessAccountTable";
import { useTranslation } from "react-i18next";

const BusinessAccountSelect = () => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const { setAccountId, authenticatedUser } = useClient();

    const handleClearAccount = () => {
        setAccountId(undefined);
        setOpen(false);
    };

    return (
        <>
            <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true}>
                <DialogTitle>{t("clients.choose")}</DialogTitle>

                <BusinessAccountTable onChange={() => setOpen(false)} />

                <DialogActions>
                    <Button onClick={handleClearAccount}>
                        {t("clients.deselect")}
                    </Button>
                    <Button onClick={() => setOpen(false)}>
                        {t("cancel")}
                    </Button>
                </DialogActions>
            </Dialog>

            <Button
                sx={{ ml: 2 }}
                onClick={() => setOpen((state) => !state)}
                variant="outlined"
                color="secondary"
                href="#outlined-buttons"
                endIcon={<ArrowDropDownIcon />}
            >
                {authenticatedUser?.businessAccount.name ? (
                    authenticatedUser?.businessAccount.name
                ) : (
                    <i>{t("clients.select")}</i>
                )}
            </Button>
        </>
    );
};

export default BusinessAccountSelect;
