import React from "react";
import { createBrowserRouter } from "react-router-dom";
import * as dashboardRoot from "../routes/dashboard/root";
import * as campaignRoot from "../routes/dashboard/campaigns/root";
import * as templateRoot from "../routes/dashboard/templates/root";
import * as templateDetail from "../routes/dashboard/templates/detail";
import * as templateCreate from "../routes/dashboard/templates/create";
import * as clientRoot from "../routes/dashboard/clients/root";
import * as clientDetail from "../routes/dashboard/clients/detail";
import * as clientCreate from "../routes/dashboard/clients/create";
import * as userCreate from "../routes/dashboard/users/create";
import * as campaignCreate from "../routes/dashboard/campaigns/create";
import * as ErrorRoot from "../routes/error";
import { ProtectedRoute } from "./middleware/ProtectedRoute";
import BusinessAccountValidator from "src/router/middleware/BusinessAccountValidator/BusinessAccountValidator";

export const router = createBrowserRouter([
    {
        path: "/",
        element: (
            <ProtectedRoute>
                <dashboardRoot.Template />
            </ProtectedRoute>
        ),
        children: [
            {
                path: "/clients",
                element: <clientRoot.Template />,
            },
            {
                path: "/clients/:id",
                element: <clientDetail.Template />,
            },
            {
                path: "/clients/:id/users/create",
                element: <userCreate.Template />,
            },
            {
                path: "/clients/create",
                element: <clientCreate.Template />,
            },
            {
                path: "/templates",
                element: (
                    <BusinessAccountValidator>
                        <templateRoot.Template />
                    </BusinessAccountValidator>
                ),
            },
            {
                path: "/templates/:id",
                element: (
                    <BusinessAccountValidator>
                        <templateDetail.Template />
                    </BusinessAccountValidator>
                ),
            },
            {
                path: "/templates/create",
                element: (
                    <BusinessAccountValidator>
                        <templateCreate.Template />
                    </BusinessAccountValidator>
                ),
            },
            {
                path: "/campaigns",
                element: (
                    <BusinessAccountValidator>
                        <campaignRoot.Template />
                    </BusinessAccountValidator>
                ),
            },
            {
                path: "/campaigns/create",
                element: (
                    <BusinessAccountValidator>
                        <campaignCreate.Template />
                    </BusinessAccountValidator>
                ),
            },
        ],
    },
    {
        path: "*",
        element: <ErrorRoot.Template />,
    },
]);
