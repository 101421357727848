import { KeycloakInitOptions } from "keycloak-js";
import { useCallback, useEffect, useState } from "react";
import keycloak from "src/keycloak";

/**
 * Hook to create a context instance for Keycloak, which automatically initializes upon mount.
 *
 * @returns Constructed Keycloak context instance with state
 */
export const useKeycloakAsContext = () => {
    const [keycloakInstance] = useState(keycloak);
    const [loading, setLoading] = useState(false);
    const [initialized, setInitialized] = useState(false);

    const init = useCallback(
        (options?: KeycloakInitOptions) => {
            setLoading(true);
            keycloakInstance
                .init({
                    onLoad: "check-sso",
                    silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
                    ...options,
                })
                .then(() => {
                    setInitialized(true);
                    setLoading(false);
                })
                .catch(() => {
                    alert(
                        "Er is een fout opgetreden, probeer het later opnieuw"
                    );
                });
        },
        [setLoading, keycloakInstance, setInitialized]
    );

    // Reset initialization flag when the instance changes
    useEffect(() => {
        if (!keycloakInstance) return;
        setInitialized(false);
    }, [keycloakInstance, setInitialized]);

    // Init when not initialized
    useEffect(() => {
        if (!initialized && !loading) {
            init();
        }
    }, [initialized, init, loading]);

    return {
        keycloak: keycloakInstance,
        authenticated: keycloakInstance.authenticated ?? false,
        initialized,
        loading,
    };
};

export default useKeycloakAsContext;
