import React from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import { useFormContext } from "react-hook-form";
import { TemplateFormProps } from "./TemplateForm.types";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { Box, MenuItem, Select } from "@mui/material";
import CallToActionButtons from "./CallToActionButtons/CallToActionButtons";
import QuickReplyButtons from "./QuickReplyButtons/QuickReplyButtons";

export const TemplateCreate = ({
    onSubmit,
    loading,
}: {
    onSubmit: (...args: any) => void;
    loading: boolean;
}) => {
    const {
        register,
        formState: { errors },
        watch,
    } = useFormContext<TemplateFormProps>();
    const { t } = useTranslation();

    return (
        <div>
            <Stack spacing={2}>
                <FormControl fullWidth>
                    <TextField
                        {...register("name", { required: true })}
                        required
                        label={t("templates.form.name")}
                        helperText={errors.name && t("form.field-is-required")}
                        error={errors.name ? true : false}
                        data-test-id="template-name"
                    />
                </FormControl>

                <FormControl fullWidth>
                    <TextField
                        {...register("message", { required: true })}
                        id="message"
                        label={t("templates.form.text")}
                        multiline
                        required
                        rows={4}
                        error={errors.message ? true : false}
                        data-test-id="template-content"
                    />
                </FormControl>

                <Box>
                    <Stack spacing={2}>
                        <FormControl fullWidth>
                            <Select
                                {...register("buttonTypes")}
                                data-test-id="template-button-type"
                                defaultValue="NO_BUTTONS"
                            >
                                <MenuItem value="NO_BUTTONS">
                                    {t("templates.form.buttons.select.empty")}
                                </MenuItem>
                                <MenuItem value="CALL_TO_ACTION">
                                    {t("templates.form.buttons.select.cta")}
                                </MenuItem>
                                <MenuItem value="QUICK_REPLY">
                                    {t("templates.form.buttons.select.reply")}
                                </MenuItem>
                            </Select>
                        </FormControl>

                        {watch("buttonTypes") === "CALL_TO_ACTION" && (
                            <CallToActionButtons />
                        )}
                        {watch("buttonTypes") === "QUICK_REPLY" && (
                            <QuickReplyButtons />
                        )}
                    </Stack>
                </Box>

                <div>
                    <LoadingButton
                        data-test-id="template-submit"
                        variant="contained"
                        onClick={onSubmit}
                        loading={loading}
                        color="secondary"
                    >
                        {t("templates.create.submit")}
                    </LoadingButton>
                </div>
            </Stack>
        </div>
    );
};

export default TemplateCreate;
