import React from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { BusinessAccountUserFormProps } from "./BusinessAccountUserForm.types";
import ErrorMessages from "src/components/atoms/ErrorMessages/ErrorMessages";

export const BusinessAccountUserForm = ({
    onSubmit,
    loading,
}: {
    onSubmit: (...args: any) => void;
    loading: boolean;
}) => {
    const {
        register,
        formState: { errors },
    } = useFormContext<BusinessAccountUserFormProps>();
    const { t } = useTranslation();

    return (
        <div>
            <Stack spacing={2}>
                <FormControl fullWidth>
                    <TextField
                        {...register("firstName", { required: true })}
                        required
                        label={t("users.form.firstName")}
                        helperText={
                            <ErrorMessages errors={errors} name="firstName" />
                        }
                        error={Boolean(errors.firstName)}
                        data-test-id="user-firstName"
                    />
                </FormControl>

                <FormControl fullWidth>
                    <TextField
                        {...register("lastName", { required: true })}
                        required
                        label={t("users.form.lastName")}
                        helperText={
                            <ErrorMessages errors={errors} name="lastName" />
                        }
                        error={Boolean(errors.lastName)}
                        data-test-id="user-lastName"
                    />
                </FormControl>

                <FormControl fullWidth>
                    <TextField
                        {...register("email", { required: true })}
                        type="email"
                        required
                        label={t("users.form.email")}
                        helperText={
                            <ErrorMessages errors={errors} name="email" />
                        }
                        error={Boolean(errors.email)}
                        data-test-id="user-email"
                    />
                </FormControl>

                <div>
                    <LoadingButton
                        data-test-id="template-submit"
                        variant="contained"
                        onClick={onSubmit}
                        loading={loading}
                        color="secondary"
                    >
                        {t("users.create.submit")}
                    </LoadingButton>
                </div>
            </Stack>
        </div>
    );
};

export default BusinessAccountUserForm;
