import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    Paper,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TemplateFormProps } from "../TemplateForm.types";

export const CallToActionButtons = () => {
    const { register, getFieldState, watch } =
        useFormContext<TemplateFormProps>();
    const { t } = useTranslation();

    const requiredMessage = () => {
        return t("form.field-is-required") || true;
    };

    return (
        <Stack spacing={2} data-test-id="call-to-action-buttons">
            {[
                {
                    title: t("templates.form.buttons.website.title"),
                    value: t("templates.form.buttons.website.value"),
                    label: t("templates.form.buttons.website.label"),
                    testId: "website",
                },
                {
                    title: t("templates.form.buttons.phone.title"),
                    value: t("templates.form.buttons.phone.value"),
                    label: t("templates.form.buttons.phone.label"),
                    testId: "phonenumber",
                },
            ].map((item, index) => (
                <Paper key={index} sx={{ p: 2 }} data-test-id={item.testId}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Typography variant="subtitle2">
                            {item.title}
                        </Typography>
                    </Box>
                    <Stack spacing={1}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    data-test-id="disable-checkbox"
                                    {...register(`buttons.${index}.disabled`)}
                                />
                            }
                            label={t("templates.form.buttons.hide-field")}
                        />
                        {!watch(`buttons.${index}.disabled`) && (
                            <Box
                                sx={{
                                    display: "flex",
                                    gap: 2,
                                }}
                            >
                                <FormControl fullWidth>
                                    <TextField
                                        {...register(`buttons.${index}.text`, {
                                            required: requiredMessage(),
                                        })}
                                        required
                                        size="small"
                                        label={item.label}
                                        disabled={watch(
                                            `buttons.${index}.disabled`
                                        )}
                                        error={Boolean(
                                            getFieldState(
                                                `buttons.${index}.text`
                                            ).error
                                        )}
                                        helperText={
                                            getFieldState(
                                                `buttons.${index}.text`
                                            ).error?.message
                                        }
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <TextField
                                        {...register(`buttons.${index}.value`, {
                                            required: requiredMessage(),
                                        })}
                                        size="small"
                                        required
                                        disabled={watch(
                                            `buttons.${index}.disabled`
                                        )}
                                        label={item.value}
                                        error={Boolean(
                                            getFieldState(
                                                `buttons.${index}.value`
                                            ).error
                                        )}
                                        helperText={
                                            getFieldState(
                                                `buttons.${index}.value`
                                            ).error?.message
                                        }
                                    />
                                </FormControl>
                            </Box>
                        )}
                    </Stack>
                </Paper>
            ))}
        </Stack>
    );
};

export default CallToActionButtons;
