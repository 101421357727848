import React, { createContext, useEffect, useRef, useState } from "react";
import Snackbar from "src/components/organisms/Snackbar/Snackbar";

import {
    SnackbarProps,
    SnackbarType,
    SnackbarContextType,
} from "./Snackbar.types";

export const SnackbarContext = createContext<SnackbarContextType | undefined>(
    undefined
);

export const SnackbarProvider = (props: SnackbarProps) => {
    const { children } = props;
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [snackbar, setSnackbar] = useState<SnackbarType>();
    const timeout = useRef(0);

    const createSnackbar = (snackbar: SnackbarType) => {
        clearTimeout(timeout.current);
        setSnackbar(snackbar);
        setIsOpen(true);
    };

    const closeSnackbar = () => {
        setSnackbar(undefined);
        setIsOpen(false);
    };

    const context = {
        isOpen,
        snackbar,
        createSnackbar,
        closeSnackbar,
    };

    useEffect(() => {
        if (snackbar) {
            timeout.current = window.setTimeout(() => {
                setIsOpen(false);
                setSnackbar(undefined);
            }, 4000);
        }
    }, [snackbar, timeout]);

    return (
        <SnackbarContext.Provider value={context}>
            {children}
            <Snackbar />
        </SnackbarContext.Provider>
    );
};
