import React from "react";
import { useForm } from "react-hook-form";
import { FormProvider } from "react-hook-form";
import { CreateCampaignFormProps } from "../organisms/CreateCampaignForm/CreateCampaignForm.types";
import CreateCampaignForm from "../organisms/CreateCampaignForm/CreateCampaignForm";
import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCreateCampaignMutation } from "src/.graphql/graphql";
import { useNavigate } from "react-router-dom";
import useSnackbar from "../../hooks/useSnackbar/useSnackbar";
import ApolloErrors from "../atoms/ApolloErrors/ApolloErrors";

export const CampaignCreate = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { createSnackbar } = useSnackbar();

    //mutations
    const [mutate, { loading, error }] = useCreateCampaignMutation({
        refetchQueries: ["getCampaigns"],
        onCompleted: () => {
            createSnackbar({
                content: t("campaigns.create.succes"),
            });
            navigate("/campaigns");
        },
        onError: (error) => {
            console.log("error", error);
            createSnackbar({
                content: t("campaigns.create.error"),
                type: "error",
            });
        },
    });

    const methods = useForm<CreateCampaignFormProps>();

    const onSubmit = async (data: CreateCampaignFormProps) => {
        try {
            mutate({
                variables: {
                    input: {
                        templateId: data.template,
                        campaignName: data.name,
                    },
                    data: data.file[0],
                },
            });
        } catch {
            createSnackbar({
                type: "error",
                content: t("exeptions.unknown"),
            });
        }
    };

    return (
        <Container>
            <Grid>
                <Paper>
                    <Box
                        sx={{
                            p: 2,
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography variant="h6">
                            {t("campaigns.create.title")}
                        </Typography>
                    </Box>

                    <FormProvider {...methods}>
                        <ApolloErrors error={error} />

                        <CreateCampaignForm
                            loading={loading}
                            onSubmit={methods.handleSubmit(onSubmit)}
                        />
                    </FormProvider>
                </Paper>
            </Grid>
        </Container>
    );
};

export default CampaignCreate;
