import React from "react";
import {
    WhatsappMessageStyled,
    MessageStyled,
    ButtonsStyled,
    ButtonStyled,
    MessageEmptyStyled,
} from "./WhatsappMessage.styles";
import LaunchIcon from "@mui/icons-material/Launch";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { whatsToHtml } from "src/lib/whatsappFormatter";
import { useTranslation } from "react-i18next";

const WhatsappMessage = ({
    message,
    buttons,
}: {
    message: string;
    buttons?: { type: string; text: string }[];
}) => {
    const { t } = useTranslation();

    return (
        <WhatsappMessageStyled>
            {message ? (
                <MessageStyled
                    dangerouslySetInnerHTML={{ __html: whatsToHtml(message) }}
                />
            ) : (
                <MessageEmptyStyled>
                    {t("whatsapp-preview.empty")}
                </MessageEmptyStyled>
            )}
            {buttons && buttons.length > 0 && (
                <ButtonsStyled>
                    {buttons.map((button, index: number) => (
                        <>
                            {button.text && (
                                <ButtonStyled key={index}>
                                    {button.type === "URL" && <LaunchIcon />}
                                    {button.type === "PHONE_NUMBER" && (
                                        <LocalPhoneIcon />
                                    )}
                                    {button.text}
                                </ButtonStyled>
                            )}
                        </>
                    ))}
                </ButtonsStyled>
            )}
        </WhatsappMessageStyled>
    );
};

export default WhatsappMessage;
