import {
    FormControl,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    GetBusinessAccountsQuery,
    useGetBusinessAccountsQuery,
} from "src/.graphql/graphql";
import useClient from "src/hooks/useClient/useClient";

export const BusinessAccountTable = ({
    onChange,
}: {
    onChange?: (...args: any) => void;
}) => {
    const { t } = useTranslation();
    const pageSize = 10;
    const [page, setPage] = useState(0);
    const [pageData, setPageData] = React.useState<GetBusinessAccountsQuery>();
    const { setAccountId } = useClient();
    const [clientName, setClientName] = useState("");

    const { data } = useGetBusinessAccountsQuery({
        variables: {
            name: clientName,
            input: {
                pageSize,
                page,
            },
        },
    });

    // prevent empty table because data is undefined if table is loading
    useEffect(() => {
        if (data) {
            setPageData(data);
        }
    }, [data]);

    const handlePageChange = (page: number) => {
        setPage(page);
    };

    const handleSetBusinessAccount = (id: string) => {
        typeof onChange === "function" && onChange();

        setAccountId(id);
    };

    return (
        <div>
            <Box sx={{ p: 2 }}>
                <FormControl fullWidth>
                    <TextField
                        size="small"
                        label={"Zoek op klantnaam"}
                        data-test-id="user-lastName"
                        value={clientName}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setClientName(e.target.value)
                        }
                    />
                </FormControl>
            </Box>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                {t("clients.name")}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {pageData?.getBusinessAccounts.rows.map(
                            (item, index) => (
                                <TableRow hover key={index}>
                                    <TableCell
                                        onClick={() =>
                                            handleSetBusinessAccount(item.id)
                                        }
                                    >
                                        {item.name}
                                    </TableCell>
                                </TableRow>
                            )
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {pageData && (
                <TablePagination
                    rowsPerPageOptions={[pageSize]}
                    component="div"
                    count={pageData?.getBusinessAccounts.rowCount}
                    rowsPerPage={pageSize}
                    page={page}
                    onPageChange={(_, newPage) => handlePageChange(newPage)}
                />
            )}
        </div>
    );
};

export default BusinessAccountTable;
