import Keycloak from "keycloak-js";
import { ClientContextType } from "src/contexts/Client/Client.types";

const fetchWithCredentials = async ({
    keycloak,
    uri,
    options: _options,
    client,
}: {
    keycloak: Keycloak;
    uri: URL | RequestInfo;
    client?: ClientContextType;
    options?: RequestInit;
}) => {
    const options: RequestInit = {
        ...(_options || {}),
    };

    if (keycloak.token) {
        options.headers = {
            ...options.headers,
            Authorization: `Bearer ${keycloak.token}`,
        };
    }

    if (client?.authenticatedUser?.token) {
        await client.refreshToken();

        options.headers = {
            ...options.headers,
            ActOnBehalfOf: client.authenticatedUser.token,
        };
    }

    try {
        await keycloak.updateToken(5);
    } catch (error) {
        console.error(error);
        keycloak.login();
    }

    return fetch(uri, options);
};

export default fetchWithCredentials;
