import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
    typography: {
        fontFamily: '"proxima" , "Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: 14,
        htmlFontSize: 16,
        fontWeightLight: 300,
        fontWeightMedium: 700,
        fontWeightRegular: 400,
        h1: {
            fontFamily: '"dunbar" , "Roboto", "Helvetica", "Arial", sans-serif',
        },
        h2: {
            fontFamily: '"dunbar" , "Roboto", "Helvetica", "Arial", sans-serif',
        },
        h3: {
            fontFamily: '"dunbar" , "Roboto", "Helvetica", "Arial", sans-serif',
        },
        h4: {
            fontFamily: '"dunbar" , "Roboto", "Helvetica", "Arial", sans-serif',
        },
        h5: {
            fontFamily: '"dunbar" , "Roboto", "Helvetica", "Arial", sans-serif',
        },
        h6: {
            fontFamily: '"dunbar" , "Roboto", "Helvetica", "Arial", sans-serif',
        },
        button: {
            fontFamily: '"dunbar" , "Roboto", "Helvetica", "Arial", sans-serif',
        },
    },
    palette: {
        primary: { main: "#0b224c", contrastText: "#ffffff" },
        secondary: { main: "#00D0B0", contrastText: "#ffffff" },
        error: { main: "#f44336", dark: "#a52a21" },
        warning: { main: "#efad41" },
        lightBackground: { main: "#ffffff" },
        greyBackground: { main: "#F1F3F4" },
        defaultBorder: { main: "#C4C4C4" },
        inputColor: { main: "#666666" },
        defaultColor: { main: "#212121" },
    },
});
