import React from "react";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import { useFormContext } from "react-hook-form";
import { CreateCampaignFormProps } from "./CreateCampaignForm.types";
import { useTranslation, Trans } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import {
    useGetTemplateByIdQuery,
    useGetTemplatesQuery,
} from "src/.graphql/graphql";
import FormHelperText from "@mui/material/FormHelperText";
import UploadField from "../../molucules/UploadField/UploadField";
import { StyledInfo } from "./CreateCampaignForm.styles";
import { Link } from "react-router-dom";
import { Box, Grid, TextField, Typography } from "@mui/material";
import WhatsappMessage from "src/components/atoms/WhatsappMessage/WhatsappMessage";

export const TemplateCreate = ({
    onSubmit,
    loading,
}: {
    onSubmit: (...args: any) => void;
    loading: boolean;
}) => {
    const {
        register,
        formState: { errors },
        watch,
    } = useFormContext<CreateCampaignFormProps>();
    const { t } = useTranslation();

    const { data, loading: templatesLoading } = useGetTemplatesQuery({
        fetchPolicy: "cache-and-network",
        variables: {
            pagination: {
                pageSize: 9999999, //for now, till there is a search
                page: 0,
            },
        },
    });

    const { data: templateData } = useGetTemplateByIdQuery({
        variables: {
            id: watch("template"),
        },
    });

    return (
        <Box bgcolor={"lightBackground.main"} sx={{ p: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={8}>
                    <Stack spacing={2}>
                        <FormControl
                            fullWidth
                            error={errors.file ? true : false}
                        >
                            <StyledInfo>
                                <Typography variant="h5">1. </Typography>
                                <Trans i18nKey="campaigns.create.csv-info">
                                    Upload een CSV bestand met je publiek,
                                    download{" "}
                                    <a download href="/voorbeeld.csv">
                                        link
                                    </a>{" "}
                                    een voorbeeldbestand.
                                </Trans>
                            </StyledInfo>
                            <UploadField
                                name="file"
                                error={errors.file ? true : false}
                                required
                            />
                            {errors.file && (
                                <FormHelperText>
                                    {t("form.field-is-required")}
                                </FormHelperText>
                            )}
                        </FormControl>

                        <StyledInfo>
                            <Typography variant="h5">2. </Typography>
                            <Trans i18nKey="campaigns.create.choose-template-info">
                                Selecteer een template die je hebt aangemaakt.
                                Staat er geen template? Maak er 1 aan in het
                                tabblad <Link to={"/templates"}>templates</Link>
                            </Trans>
                        </StyledInfo>
                        <FormControl
                            fullWidth
                            error={errors.template ? true : false}
                        >
                            <InputLabel id="create-template-templates-label">
                                {t("campaigns.create.choose-template")}
                            </InputLabel>
                            {!templatesLoading && (
                                <Select
                                    {...register("template", {
                                        required: true,
                                    })}
                                    labelId="create-template-templates-label"
                                    id="template-select"
                                    label={t(
                                        "campaigns.create.choose-template"
                                    )}
                                    data-test-id="campaign-template"
                                    required
                                    defaultValue={""}
                                >
                                    {data?.getTemplates?.rows.map((row) => (
                                        <MenuItem
                                            key={row.id}
                                            value={row.id}
                                            disabled={row.status !== "APPROVED"}
                                        >
                                            {row.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                            {errors.template && (
                                <FormHelperText>
                                    {t("form.field-is-required")}
                                </FormHelperText>
                            )}
                        </FormControl>

                        <StyledInfo>
                            <Typography variant="h5">3. </Typography>
                            <Trans i18nKey="campaigns.create.give-campaign-name-info">
                                Geef optioneel je campagne een naam. Laat je
                                deze leeg, dan zal het systeem op basis van de
                                template-naam en het tijdstip een naam
                                genereren.
                            </Trans>
                        </StyledInfo>
                        <FormControl
                            fullWidth
                            error={errors.name ? true : false}
                        >
                            <TextField
                                {...register("name", { required: true })}
                                id="name"
                                label={t("campaigns.create.give-campaign-name")}
                                required={false}
                                rows={4}
                                error={errors.name ? true : false}
                                data-test-id="template-content"
                            />
                        </FormControl>

                        <StyledInfo>
                            <Typography variant="h5">4. </Typography>
                            {t("campaigns.create.send-campaing-info")}
                        </StyledInfo>
                        <div>
                            <LoadingButton
                                variant="contained"
                                onClick={onSubmit}
                                loading={loading}
                                data-test-id="campaign-submit"
                                color="secondary"
                            >
                                {t("campaigns.create.submit")}
                            </LoadingButton>
                        </div>
                    </Stack>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                    {watch("template") && templateData?.getTemplateById && (
                        <>
                            <Typography variant="h6" sx={{ mb: 2 }}>
                                {t("choosen-template")}
                            </Typography>
                            <WhatsappMessage
                                message={
                                    templateData?.getTemplateById.bodyComponent
                                        .text || ""
                                }
                                buttons={
                                    templateData?.getTemplateById
                                        .buttonComponents
                                }
                            />
                        </>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default TemplateCreate;
