import { ErrorMessage } from "@hookform/error-message";
import React from "react";
import { useTranslation } from "react-i18next";

export const ErrorMessages = ({
    errors,
    name,
}: {
    errors: React.ComponentProps<typeof ErrorMessage>["errors"];
    name: React.ComponentProps<typeof ErrorMessage>["name"];
}) => {
    const { t } = useTranslation();

    return (
        <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => t(message)}
        />
    );
};

export default ErrorMessages;
