import { ApolloError as ApolloErrorType } from "@apollo/client/errors";
import { Alert, Stack } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export const ApolloErrors = ({ error }: { error?: ApolloErrorType }) => {
    const { t } = useTranslation();

    return error ? (
        <div>
            {error && error.graphQLErrors && (
                <Stack sx={{ width: "100%", mb: 2 }} spacing={4}>
                    {error.graphQLErrors.map((item, index) => (
                        <Alert severity="error" key={index}>
                            {t(`exeptions.${item.extensions?.exception}`)}
                        </Alert>
                    ))}
                </Stack>
            )}
        </div>
    ) : null;
};

export default ApolloErrors;
