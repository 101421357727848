import React, { useMemo } from "react";
import { List } from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import PersonIcon from "@mui/icons-material/Person";
import CampaignIcon from "@mui/icons-material/Campaign";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { MainMenuProps } from "./MainMenu.types";
import MenuItem from "src/components/molucules/MenuItem/MenuItem";
import { useTranslation } from "react-i18next";
import { useKeycloak } from "src/providers/KeycloakProvider";
import { UserRole } from "src/types/userRoles";

export const Layout = ({ open }: MainMenuProps) => {
    const { t } = useTranslation();
    const { keycloak } = useKeycloak();

    const menu = useMemo(() => {
        const menuItems = [];

        if (keycloak.hasRealmRole(UserRole.SuperAdmin)) {
            menuItems.push({
                text: t("menu.clients"),
                href: "/clients",
                icon: <PersonIcon />,
            });
        }

        menuItems.push(
            {
                text: t("menu.templates"),
                href: "/templates",
                icon: <ChatIcon />,
            },
            {
                text: t("menu.campaigns"),
                href: "/campaigns",
                icon: <CampaignIcon />,
            },
            {
                text: t("menu.botsquad"),
                href: "https://studio.botsqd.com/",
                icon: <OpenInNewIcon />,
                external: true,
            }
        );

        return menuItems;
    }, [keycloak]);

    return (
        <List>
            {menu.map((item) => (
                <MenuItem
                    href={item.href}
                    hideText={open}
                    icon={item.icon}
                    text={item.text}
                    key={item.text}
                    external={item.external}
                />
            ))}
        </List>
    );
};

export default Layout;
