import { useContext } from "react";
import { ClientContext } from "src/contexts/Client/Client.provider";
import { ClientContextType } from "src/contexts/Client/Client.types";

export const useClient = (): ClientContextType => {
    const context = useContext(ClientContext);

    if (context === undefined) {
        throw new Error("useClient must be used within a SnackBarProvider");
    }

    return context;
};

export default useClient;
